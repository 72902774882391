import { template as template_f22095cffd0a4a4da8b9c7e9c580b950 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f22095cffd0a4a4da8b9c7e9c580b950(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
